.line {
  border-bottom: 2px solid lightgray;
  margin: 30px 0 0 0;
}

.flexWrapper {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  padding: 10px 0;
}

.flexWrapperCenter {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 0;
}

.flexWrapperIcon {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
}

.jobcontainer {
  padding: 20px 0;
}

.draganddrop {
  background-color: white;
  border: 2px dotted lightgray;
  padding: 10px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  width: 100%;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 33%;
}

.previewTitle {
  font-size: 10px;
}

.previewContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.previewCard {
  width: 200px;
  height: 130px;
  padding: 8px;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  font-size: 12px;
  color: #333;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.previewCard:hover {
  transform: translateY(-4px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
}

.cardContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.cardItemFileName {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 2px;
  color: #333;
  word-break: break-all;
}

.cardItemType {
  font-size: 10px;
  color: #777;
}

.deleteButton {
  margin-top: 6px;
  padding: 5px 8px;
  background-color: #E74C3C;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 11px;
  transition: background-color 0.3s ease;
}

.deleteButton:hover {
  background-color: #C0392B;
}

.formContainer{
  margin-bottom: 40px;
}