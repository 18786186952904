.react-datepicker-popper {
    z-index: 2 !important;
    margin-top: 20px;
    width: auto;
}

.react-datepicker__triangle {
    left: -10px !important;
}

.react-datepicker-wrapper {
    width: 100% !important;
}