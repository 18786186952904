.sidebar {
  height: 100vh;
  background-color: var(--black_color);

  position: fixed;
  transition: width 0.3s ease, left 0.3s ease-in-out;
  /* Ensure both width and position transitions */
  padding: 15px;
  left: 0;
  overflow-y: auto;
}

.sidebar.closed {
  overflow: hidden;
}

.sidebar.open {
  width: 250px;
  /* Adjust this width as needed */
}


.sidebar-toggle {
  top: 10px;
  height: 20px;
  background-color: var(--black_color);
  /* Adjust background color */
  color: white;
  /* Adjust text color */
  font-size: 20px;
  /* Adjust font size */
  display: flex;
  justify-content: end;
  cursor: pointer;
  z-index: 200;
}